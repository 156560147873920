body {
  height: auto;
  font-family: 'EB Garamond', serif;
}

@font-face {
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/ProximaNova-Regular.otf');
}
